export default {
  namespaced:true,
  state: {
    expandedSearch: '0',
    promoContext: true,
    showFormModal: false,
    showDispatchModal: false,
    showDispatchExternalModal: false,
    currentRow: {},
  },
  getters: {
    expandedSearch: (state) => {
      return state.expandedSearch
    },
  },
  mutations: {
    ['TOGGLE_SEARCH'](state) {
      state.expandedSearch = state.expandedSearch === '0' ? '350px' : '0'
    },
    ['TOGGLE_PROMO_CONTEXT'](state) {
      state.promoContext = !state.promoContext
    },
    ['SET_FORM_MODAL'](state, value) {
      state.showFormModal = value
    },
    ['SET_DISPATCH_MODAL'](state, value) {
      state.showDispatchModal = value
    },
    ['SET_DISPATCH_EXTERNAL_MODAL'](state, value) {
      state.showDispatchExternalModal = value
    },
    ['SET_CURRENT_ROW'](state, value) {
      state.currentRow = value
    },
  },
  actions: {
    toggleSearch({commit}) {
      commit('TOGGLE_SEARCH')
    },
    togglePromoContext({commit}) {
      commit('TOGGLE_PROMO_CONTEXT')
    },
    openFormModal({commit}) {
      commit('SET_FORM_MODAL', true)
    },
    closeFormModal({commit}) {
      commit('SET_FORM_MODAL', false)
    },
    openDispatchModal({commit}) {
      commit('SET_DISPATCH_MODAL', true)
    },
    openDispatchExternalModal({commit}) {
      commit('SET_DISPATCH_EXTERNAL_MODAL', true)
    },
    closeDispatchModal({commit}) {
      commit('SET_DISPATCH_MODAL', false)
    },
    closeDispatchExternalModal({commit}) {
      commit('SET_DISPATCH_EXTERNAL_MODAL', false)
    },
    clean({commit, dispatch}) {
      dispatch('closeFormModal')
      dispatch('closeDispatchModal')
      dispatch('closeDispatchExternalModal')
      commit('SET_CURRENT_ROW', {})
    },
    setCurrentRow({commit}, currentRow) {
      commit('SET_CURRENT_ROW', currentRow)
    },
    startDispatch({commit, dispatch}, rowData) {
      commit('SET_CURRENT_ROW', rowData)
      dispatch('openDispatchModal')
    },
    startDispatchExternal({commit, dispatch}, rowData) {
      commit('SET_CURRENT_ROW', rowData)
      dispatch('openDispatchExternalModal')
    },
    addTemplate({dispatch}) {
      dispatch('clean')
      dispatch('openFormModal')
    },
  }
}
