<template>
  <footer>
    <div class="row">
      <div class="col-md-12 copyright text-center" style="color: white !important; font-size: smaller;">
        Copyright &copy; 2018-{{ year }} All Rights Reserved by Caprico Ltd. v{{ appVersion }}
      </div>
    </div>
  </footer>
</template>
<script>
  import Vue from 'vue'

  Vue.use(require('vue-moment'))

  export default {
    name: 'main-footer',
    computed: {
      appVersion() {
        return this.$store.getters.appVersion
      }
    },
    data () {
      return {
        year: this.$moment().format('YYYY')
      }
    }
  }
</script>
<style>
</style>
