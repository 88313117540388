<template>
  <div class="login-page" :style.sync="bgColorReg">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-8 col-sm-12 col-12 mx-auto">
              <info-section  direction="vertical" class="mt-5">
                <div class="circles d-block">
                  <p class="logo mx-auto d-block">
                    <RemoteImg :src="logoLogin"></RemoteImg>
                  </p>
                  <span class="circle big"></span>
                  <span class="circle med"></span>
                  <span class="circle small"></span>
                </div>
                <h1 class="text-white">Under maintenance</h1>
                <small class="text-white">
                  <b>
                    We are excited to announce that we’ve been working for a while on a massive update to our platform which will help take HokoCloud to the next level.
                  </b>
                  <br>
                  <br>
                    <b>This will occur on the weekend of May 28th and May 29th.</b>
                </small>
                <br>
              </info-section>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import {Card, Checkbox, Button, InfoSection} from 'src/components/UIComponents';
  import AppFooter from './../Dashboard/Views/Pages/Layout/AppFooter';
  import PublicSupport from "src/components/Dashboard/Views/Pages/PublicSupport.vue"

  export default {
    components: {
      Card,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      PublicSupport
    },
    data(){
      return {
        color: "#3ABED4",
        settings: undefined,
        forceRender: false,
      }
    },
    computed: {
      bgColorReg () {
        return `background-color: ${this.color};`;
      },
      logoLogin () {
        return this.settings ? this.settings.logo_login : '';
      },
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    created () {
      this.settings = localStorage.getItem('wlSettings');
      if(this.settings && this.settings.bgcolor) {
        this.color = this.settings.bgcolor;
      }
    },
    beforeDestroy () {
      this.closeMenu()
    },
  }
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
.login-page::v-deep {
  text-align: center;
  h1 {
    margin-top: 6rem;
  }
  small {
    font-size: small;
  }
  .logo {
    position: relative;
    z-index: 100;
    margin-top: 8rem;
    img {
      z-index: 150;
    }
  }
  .btn {
    background-color: transparent !important;
    border: 2px solid white !important;
  }
  .circles:after {
    content:'';
    display:inline-block;
    width:100%;
    height:100px;
    position:absolute;
    top:-100px;
    left:0;
    transform:skewY(-4deg);
    -webkit-transform:skewY(-4deg);
  }
  .circles {
    position: relative;
    text-align: center;
    margin-top:-150px;
  }
  .circles .circle.small {
    z-index: 1;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 50%;
    animation: 6s smallmove infinite cubic-bezier(1,.22,.71,.98);
    -webkit-animation: 6s smallmove infinite cubic-bezier(1,.22,.71,.98);
    animation-delay: 1.1s;
    -webkit-animation-delay: 1.2s;
  }
  .circles .circle.med {
    z-index: 1;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 10%;
    animation: 7s medmove infinite cubic-bezier(.32,.04,.15,.75);
    -webkit-animation: 7s medmove infinite cubic-bezier(.32,.04,.15,.75);
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.4s;
  }
  .circles .circle.big {
    z-index: 1;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    right: 10%;
    animation: 8s bigmove infinite;
    -webkit-animation: 8s bigmove infinite;
    animation-delay: 3s;
    -webkit-animation-delay: 1s;
  }
  @-webkit-keyframes smallmove {
    0% { top: -12px; left: 45%; opacity: 0.1; }
    25% { top: 55px; left: 40%; opacity:0.2; }
    50% { top: 18px; left: 50%; opacity:0.3; }
    75% { top: -16px; left: 40%;  opacity:0.2; }
    100% { top: -12px; left: 45%; opacity: 0.1; }
  }
  @keyframes smallmove {
    0% { top: -12px; left: 45%; opacity: 0.1; }
    25% { top: 55px; left: 40%; opacity:0.2; }
    50% { top: 18px; left: 50%; opacity:0.3; }
    75% { top: -16px; left: 40%;  opacity:0.2; }
    100% { top: -12px; left: 45%; opacity: 0.1; }
  }
  @-webkit-keyframes medmove {
    0% { top: -12px; left: 20%; opacity: 0.15; }
    25% { top: 48px; left: 60%; opacity:0.2; }
    50% { top: 20px; left: 50%; opacity:0.4; }
    75% { top: -18px; left: 40%;  opacity:0.3; }
    100% { top: -12px; left: 20%; opacity: 0.15; }
  }
  @keyframes medmove {
    0% { top: -12px; left: 20%; opacity: 0.15; }
    25% { top: 48px; left: 60%; opacity:0.2; }
    50% { top: 20px; left: 50%; opacity:0.4; }
    75% { top: -18px; left: 40%;  opacity:0.3; }
    100% { top: -12px; left: 20%; opacity: 0.15; }
  }
  @-webkit-keyframes bigmove {
    0% { top: -10px; right: 20%; opacity: 0.2; }
    25% { top: 32px; right: 35%; opacity:0.3; }
    50% { top: 15px; right: 50%; opacity:0.5; }
    75% { top: -1px; right: 35%;  opacity:0.4; }
    100% { top: -10px; right: 20%; opacity: 0.2; }
  }
  @keyframes bigmove {
    0% { top: -10px; right: 20%; opacity: 0.2; }
    25% { top: 32px; right: 35%; opacity:0.3; }
    50% { top: 15px; right: 50%; opacity:0.5; }
    75% { top: -1px; right: 35%;  opacity:0.4; }
    100% { top: -10px; right: 20%; opacity: 0.2; }
  }
}
</style>
