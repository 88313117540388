<template>
  <fg-input
    v-on="$listeners"
    v-bind="$attrs"
    :type="currentType"
    :addon-right-icon="typeIcon"
    :disabled="isDisabled"
    class="fg-pass"
  >
    <a slot="addonRight" @click="toggleType">
      <i :class="typeIcon"></i>
    </a>
  </fg-input>
</template>

<script>
export default {
  name: "fg-pass",
  computed: {
    typeIcon() {
      return this.currentType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye';
    }
  },
  props: {
    isDisabled: {
      type: Boolean,
      description: 'if the input can be edit',
      default: false
    }
  },
  data() {
    return {
      currentType: 'password',
    }
  },
  methods: {
    toggleType() {
      if(this.$attrs.disabled) {
        return;
      }
      this.currentType = this.currentType === 'password' ? 'text' : 'password';
    }
  },
}
</script>

<style scoped lang="scss">

</style>
