export default {
  namespaced: true,
  state: {
    showDeleteMultipleAccountModal: false,
    accounts: [],
  },
  getters: {

  },
  mutations: {
    ['SET_DELETE_MULTIPLE_ACCOUNTS_MODAL'](state, value) {
      state.showDeleteMultipleAccountModal = value
    },
    ['SET_ACCOUNTS_TO_DELETE'](state, value) {
      state.accounts = value
    }
  },
  actions: {
    openDeleteMultipleAccountsModal({commit}) {
      commit('SET_DELETE_MULTIPLE_ACCOUNTS_MODAL', true)
    },
    closeDeleteMultipleAccountsModal({commit}) {
      commit('SET_DELETE_MULTIPLE_ACCOUNTS_MODAL', false)
    },
    setAccountsToDelete({commit}, accounts) {
      commit('SET_ACCOUNTS_TO_DELETE', accounts)
    },
    clean({commit, dispatch}) {
      dispatch('openDeleteMultipleAccountsModal')
      dispatch('closeDeleteMultipleAccountsModal')
    }
  }
}
