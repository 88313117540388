<template>
  <navbar navbar-menu-classes="navbar-right">
    <template slot="navbar-menu">
      <div class="mx-auto d-block" style="width: 150px; padding-bottom: 15px;">
        <RemoteImg alt="imagem" :src="logoLogin"></RemoteImg>
      </div>
    </template>
  </navbar>
</template>

<script>
  import {Navbar} from 'src/components/UIComponents'

  const settings = localStorage.getItem('wlSettings')
  export default {
    name: 'main-navbar',
    components: {
      Navbar,
    },
    data(){
     return{
       logoLogin: JSON.parse(settings).logo_login
     }
    }
  }
</script>
<style>
  RemoteImg {
    right: 200px;
  }
</style>
