/**
 * Class to format default payload given by datatables to match back-end paginator structure
 */
export class ListPayload {
  constructor(
    {
      paginate = true,
      page= 1,
      pageSize= 20,
      total= 0,
      sort= {},
      filters= {},
      list= [],
    } = Object()
  ) {
    this.paginate = paginate;
    this.page = page;
    this.size = pageSize;
    if(sort.order) {
      this.order_column = sort.prop;
      this.order_type = sort.order == 'descending' ? 'DESC' : 'ASC';
    }
    Object.keys(filters).forEach(filter => {
      this[filter] = filters[filter];
    });
  }
}

export function exportCSVFile(csv, fileName) {
    const exportedFileName = fileName ? `${fileName}.csv` : `export_${new Date().getTime()}.csv`;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFileName);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            link.setAttribute("href", URL.createObjectURL(blob));
            link.setAttribute("download", exportedFileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
