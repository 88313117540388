<template>
  <module
      ref="module"
      id="whitelabelManager"
      titleIcon="fa fa-cog"
      :title="$t('whitelabelmanagepanel')"
      :use-default-list="false"
    >
    <div slot="toolbar-global">
      <div v-if="list && !isLoading">
        <p-button type="primary" v-on:click="createNewWhitelabel()">
          <i slot="label" class="fa fa-plus"></i>
          {{ this.$t('createwhitelabel') }}
        </p-button>
        <p-button type="default" v-on:click="updateTable()">
          <i slot="label" class="fa fa-refresh"></i>
          {{ this.$t('updatelist') }}
        </p-button>
      </div>
      <div v-if="form">
        <p-button type="success" v-on:click="validateAndSave()">
          <i slot="label" class="fa fa-floppy-o"></i>
          {{ this.$t('save') }}
        </p-button>
        <p-button type="danger" v-on:click="showHideForm()">
          <i slot="label" class="fa fa-trash-o"></i>
          {{ this.$t('cancel') }}
        </p-button>
      </div>
      <div v-if="vpslist">
        <p-button slot="back" type="default" v-on:click="listvps()">
          <i class="fa fa-arrow-left"></i>
          {{ txt.back }}
        </p-button>
      </div>
    </div>
    <div class="col-md-12" slot="global">

      <div class="row" v-if="list">
        <div class="col-md-12">
          <div class="card-body">
            <div v-if="isLoading" class="col-md-12">
              <div class="text-center">
                <h2>{{ $t('loadingtxt') }}</h2>
                <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
              </div>
            </div>

            <el-table
              v-if="!isLoading" class="table-striped"
              :data="tableData"
              border
              style="width: 100%">

              <el-table-column :label="txt.whitelabel" class="success" width="200px">
                <span slot-scope="scope">
                {{ scope.row.whitelabel }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.fullname" class="success">
                <span slot-scope="scope">
                {{ scope.row.fullname }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.domain" class="success">
                <span slot-scope="scope">
                {{ scope.row.domain }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.admin" class="success" width="350px">
                <span slot-scope="scope">
                {{ scope.row.wladmin }}
                </span>
              </el-table-column>

              <el-table-column :label="txt.type" class="success" width="150px">
                <template slot-scope="props">
                  <div class="row">
                    <div v-if="props.row.type == 'broker' " class="col-md-12">
                      {{ $t('brokertxt') }}
                    </div>
                    <div v-if="props.row.type == 'trader' " class="col-md-12">
                      {{ $t('tradertxt') }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="actionlbl" class="success" width="200px">
                <div slot-scope="scope" class="text-center">
                  <div class="btn-group">
                    <p-button type="sucess" link v-on:click="listvps(`${scope.row.whitelabel}`)">
                      <i class="fa fa-server" style="font-size: x-large;"></i>
                    </p-button>
                    <p-button type="warning" link v-on:click="updaterow(`${scope.row.whitelabel}`)">
                      <i class="fa fa-pencil" style="font-size: x-large;"></i>
                    </p-button>
                    <p-button type="danger" link v-on:click="removerow(`${scope.row.whitelabel}`)">
                      <i class="fa fa-trash" style="font-size: x-large;"></i>
                    </p-button>
                  </div>
                </div>
              </el-table-column>
            </el-table>

          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>

      <div class="row" v-if="form">
        <div class="col-md-12">
          <div class="card-body">

            <div v-if="isLoading" class="col-md-12">
              <div class="text-center">
                <h2>{{ $t('loadingtxt') }}</h2>
                <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
              </div>
            </div>

            <whitelabelForm
              ref="form"
              :is_manage="true"
              v-if="!isLoading"
              v-bind:reference="whitelabel_data">
            </whitelabelForm>

          </div>
          <div class="card-footer">

          </div>
        </div>
      </div>

      <div v-if="vpslist">
        <whitelabelvps
          ref="list"
          :is_manage="true"
          v-bind:reference="whitelabel_reference">
        </whitelabelvps>
      </div>

      <!-- Modal for Confirm Removal -->
      <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t('attentiontxt') }}
        </h4>

        <div class="row">
          <div class="col-12">
            {{ $t('confirmdelwl') }}
          </div>
        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button @click.prevent="confirmRemoval" link>
              {{ $t('yestxt') }}
            </p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="danger" v-on:click="modalconfirm = false" link>
              {{ $t('notxt') }}
            </p-button>
          </div>
        </template>
      </modal>

    </div>
  </module>
</template>
<script>

  import Vue from "vue";
  import {Table} from "element-ui";
  import swal from 'sweetalert2'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Button, Modal} from 'src/components/UIComponents'
  import WhitelabelForm from "./WhitelabelForm";
  import whitelabelvps from "./WhitelabelVps";

  Vue.use(Table);

  export default {
    components: {
      Button,
      Modal,
      PPagination,
      WhitelabelForm,
      whitelabelvps
    },
    data () {
      return {
        actionlbl: this.$t('action'),
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        txt: {
          whitelabel: this.$t('whitelabel'),
          fullname: this.$t('fullname'),
          type: this.$t('type'),
          admin: 'Admin',
          back: this.$t('back'),
          domain: this.$t('domaintxt'),
        },
        tableData: [],
        modalconfirm: false,
        passid: null,
        did: null,
        list: true,
        form: false,
        whitelabel_reference: null,
        whitelabel_data: null,
        isLoading: true,
        vpslist: false,
      }
    },
    methods: {
      loadTable(response) {
        if (!response.success){
          return
        }
        this.tableData = response.data;
        this.isLoading = false;
      },
      updaterow(whitelabel) {
        this.isLoading = true;
        this.whitelabel_reference = whitelabel;
        this.$retrieve_whitelabels(whitelabel)
          .then(this.retrieveHandler, () => { });
      },
      listvps(whitelabel){
        this.whitelabel_reference = whitelabel;
        this.list = !this.list;
        this.vpslist = !this.vpslist;
      },
      showHideVPS(){
        this.list = true
        this.vpslist = false
      },
      retrieveHandler(response) {
        this.showHideForm(response.data);
        this.isLoading = false;
      },
      removerow(whitelabel) {
        this.whitelabel_reference = whitelabel;
        this.modalconfirm = true;
      },
      showHideForm(data = null) {
        this.whitelabel_data = data;
        this.list = !this.list;
        this.form = !this.form;
      },
      createNewWhitelabel() {
        this.whitelabel_reference = null;
        this.showHideForm();
      },
      callBackRemove() {
        this.whitelabel_reference = null;
        this.modalconfirm = false;
        this.updateTable();
        swal(this.$t('successtxt'), this.$t('wlremove'), "success")
      },
      confirmRemoval() {
        this.$delete_whitelabel(this.whitelabel_reference)
          .then(this.callBackRemove, () => { })
      },
      updateTable() {
        this.isLoading = true;
        this.$list_whitelabels()
          .then(this.loadTable, () => { });
      },
      validateAndSave() {
        this.$refs.form.validate()
          .then(this.submit, () => { });
      },
      submit(isValid) {
        if (!isValid) {
          return;
        }
        if (this.$refs.form.isNewPolicieFile()) {
          this.$refs.form.submitFiles().then(() => {}, () => {});
        }
        this.isLoading = true;
        const data = this.$refs.form.getData();
        this.$persist_whitelabel(this.whitelabel_reference, data)
          .then(this.persistSuccessHandler, this.persistErrorHandler)
      },
      persistSuccessHandler(response) {
        this.isLoading = false;
        if (!response.success) {
          this.persistErrorHandler();
        }
        this.showHideForm();
        this.updateTable();
        swal(this.$t('successtxt'),this.whitelabel_reference ? this.$t("wlupdated") : this.$t("wlcreated") , "success")
        this.whitelabel_reference = null;
      },
      persistErrorHandler() {
        this.isLoading = false;
        swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "error")
      },
      getLogo(logo) {
        return "https://hoko.ams3.digitaloceanspaces.com/brokerlogos/" + logo + ".png"
      }
    },
    mounted () {
      this.updateTable();
    }
  }
</script>
<style>
</style>
