<template>
  <div :class="`fg-numeric form-group ${hasLabel}`">
    <label v-if="label">{{ label }}</label>
    <el-input-number :class="`form-control valid ${isPercentage} ${inputClasses}`"
                     v-on="$listeners"
                     v-bind="$attrs"
    ></el-input-number>
  </div>
</template>

<script>
import Vue from 'vue';
import {InputNumber} from 'element-ui';

Vue.use(InputNumber)

export default {
  name: "fg-numeric",
  props: {
    label: String,
    percentage: Boolean,
    inputClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasLabel() {
      return this.label ? 'has-label' : '';
    },
    isPercentage() {
      return this.percentage ? 'percentage' : '';
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/sass/paper/variables';
.fg-numeric {
  .form-control.el-input-number::v-deep {
    padding: 0 !important;
    border: none !important;
    .el-input-number__decrease,
    .el-input-number__increase {
      height: 100% !important;
      border: $border $medium-gray;
      top: 0 !important;
      .disabled &, .is-disabled &, &.is-disabled {
        color: #ccc !important;
        background-color: #f3f3f3 !important;
        border-color: #ccc !important;
      }
      i {
        height: 100% !important;
        position: relative !important;
        transform: translateY(30%);
        .modal-body & {
          transform: translateY(0%);
        }
      }
    }
    .el-input-number__decrease {
      border-radius: $border-radius-small 0 0 $border-radius-small !important;
    }
    .el-input-number__increase {
      right: 0 !important;
      border-radius: 0 $border-radius-small $border-radius-small 0 !important;
    }
    &:hover {
      .el-input-number__decrease,
      .el-input-number__increase {
        border: $border $brand-info !important;
      }
    }
    .disabled &, &.is-disabled {
      pointer-events: none;
      color: #ccc !important;
      background-color: #f3f3f3 !important;
      .el-input-number__decrease,
      .el-input-number__increase {
        color: #ccc !important;
        background-color: #f3f3f3 !important;
        border-color: #ccc !important;
      }
    }
    &.percentage::after {
      content: '%';
      position: absolute;
      top: calc(50% - 0.5rem);
      right: calc(50% - 2.2rem);
      transition: all .05s ease-in-out;
    }
    &.percentage.xs::after {
      font-size: xx-small;
      top: calc(50% - 0.3rem);
      right: calc(50% - 1.4rem);
    }
  }
}
</style>
