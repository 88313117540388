<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right">
         Copyright &copy; 2018-{{year}} All Rights Reserved by Caprico Ltd. v{{ appVersion }}
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'ContentFooter',
    computed: {
      appVersion() {
        return this.$store.getters.appVersion
      }
    },
    data () {
      return {
        year: this.$moment().format("YYYY")
      }
    }
  }

</script>
<style>

</style>
