<template>
  <tabs pills :type="typePills" icons centered tab-content-classes="tab-space"  v-if="inprogress == false && showdata && eqReportingStatus && balReportingStatus">
    <tab-pane id="balance" key="balance">
      <span slot="title">
          {{ $t('balance') }}
      </span>

      <div class="row">

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('accumulatedBalance') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('accumulatedBalance_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="activeUsersChart.labels"
                        :datasets="activeUsersChart.accumulatedBalance.datasets"
                        :extra-options="activeUsersChart.options">
            </line-chart>
          </card>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('dailyBalanceGrowth') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('dailyBalanceGrowth_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="activeUsersChart.labels"
                        :datasets="activeUsersChart.dailyBalanceGrowth.datasets"
                        :extra-options="activeUsersChart.options">
            </line-chart>
          </card>
        </div>

      </div>

      <div class="row">

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('drawdown') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('drawdown_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="activeUsersChart.labels"
                        :data="activeUsersChart.drawdown.data"
                        :extra-options="activeUsersChart.drawdown.options">
            </line-chart>
          </card>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('effectiveLeverageUsed') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('effectiveLeverageUsed_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="activeUsersChart.labels"
                        :data="activeUsersChart.effectiveLeverageUsed.data"
                        :extra-options="activeUsersChart.effectiveLeverageUsed.options">
            </line-chart>
          </card>
        </div>

      </div>

      <div class="row">

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('percentualVolatilityEndurance') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('percentualVolatilityEndurance_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="activeUsersChart.labels"
                        :data="activeUsersChart.percentualVolatilityEndurance.data"
                        :extra-options="activeUsersChart.percentualVolatilityEndurance.options">
            </line-chart>
          </card>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('monthlyReturnBalance') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('monthlyReturnBalance_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <bar-chart :labels="activeUsersChart.monthlyReturnBalance.labels"
                       :height="150"
                       :extra-options="activeUsersChart.monthlyReturnBalance.options"
                       :datasets="activeUsersChart.monthlyReturnBalance.datasets">
            </bar-chart>
          </card>
        </div>

      </div>

      <div class="row" v-if="inprogress == false">
        <div class="col-md-12 card">
          <div class="card-header">
            <h6>{{ $t('statisticstxt') }}</h6>
            <div class="small text-muted move-right">
              {{ balChartInfo.lastupdate }}&nbsp;&nbsp;
              <el-tooltip :content="balReportingStatus" :open-delay="100" placement="top">
                <i class="fa fa-circle" :class="`text-${balStatusType}`"></i>
              </el-tooltip>
            </div>
          </div>

          <div class="card-body row">

            <div class="table-responsive col-md-12">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('totalReturnstxt') }}
                      <el-tooltip :content="`${$t('totalReturnstxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="activeUsersChart.stats.accumulatedBalance"
                      :class="(activeUsersChart.stats.accumulatedBalance > 0) ? 'text-success' : 'text-danger'">
                      {{ (activeUsersChart.stats.accumulatedBalance * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('maxEffectiveLeverageUsedtxt') }}
                      <el-tooltip :content="`${$t('maxEffectiveLeverageUsedtxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="activeUsersChart.stats.maxEffectiveLeverageUsed"
                      :class="(activeUsersChart.stats.maxEffectiveLeverageUsed <= 10) ? 'text-success' : 'text-danger'">
                      {{ (activeUsersChart.stats.maxEffectiveLeverageUsed).toFixed(2) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('monthsSinceInception') }}
                      <el-tooltip :content="`${$t('monthsSinceInception_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="activeUsersChart.stats.monthsSinceInception">
                      {{ (activeUsersChart.stats.monthsSinceInception) }}
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('currentVolatilityEndurancetxt') }}
                      <el-tooltip :content="`${$t('currentVolatilityEndurancetxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="activeUsersChart.stats.percentualVolatilityEndurance">
                      {{ (activeUsersChart.stats.percentualVolatilityEndurance * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('dailyAverageReturnstxt') }}
                      <el-tooltip :content="`${$t('dailyAverageReturnstxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="activeUsersChart.stats.dailyAverageBalance"
                      :class="(activeUsersChart.stats.dailyAverageBalance > 0) ? 'text-success' : 'text-danger'">
                      {{ (activeUsersChart.stats.dailyAverageBalance * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('riskRatio') }}
                      <el-tooltip :content="`${$t('riskRatio_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="activeUsersChart.stats.riskRatio"
                      :class="(activeUsersChart.stats.riskRatio <= 1) ? 'text-success' : 'text-danger'">
                      {{ (activeUsersChart.stats.riskRatio).toFixed(2) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('monthlyAverageReturnstxt') }}
                      <el-tooltip :content="`${$t('monthlyAverageReturnstxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="activeUsersChart.stats.monthlyAverageBalance"
                      :class="(activeUsersChart.stats.monthlyAverageBalance > 0) ? 'text-success' : 'text-danger'">
                      {{ (activeUsersChart.stats.monthlyAverageBalance * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('averageNumberofMonthlyTradestxt') }}
                      <el-tooltip :content="`${$t('averageNumberofMonthlyTradestxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="activeUsersChart.stats.avgTradesPerMonth">
                      {{ (activeUsersChart.stats.avgTradesPerMonth).toFixed(2) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('maxEffectiveDrawdown') }}
                      <el-tooltip :content="`${$t('maxEffectiveDrawdown_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="activeUsersChart.stats.maxDrawdown"
                      :class="(activeUsersChart.stats.maxDrawdown <= 0.3) ? 'text-success' : 'text-danger'">
                      {{ (activeUsersChart.stats.maxDrawdown * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('averageTradeDurationtxt') }}
                      <el-tooltip :content="`${$t('averageTradeDurationtxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="activeUsersChart.stats.avgOpenDuration">
                      {{ (activeUsersChart.stats.avgOpenDuration) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

        </div>

      </div>

    </tab-pane>

    <tab-pane id="equity" key="equity">
      <span slot="title">
          {{ $t('equity') }}
      </span>

      <div class="row">

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('accumulatedEquityGrowth') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('accumulatedEquityGrowth_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="equityUsersChart.labels"
                        :datasets="equityUsersChart.accumulatedEquityGrowth.datasets"
                        :extra-options="equityUsersChart.options">
            </line-chart>
          </card>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('dailyEquityGrowth') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('dailyEquityGrowth_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="equityUsersChart.labels"
                        :datasets="equityUsersChart.dailyEquityGrowth.datasets"
                        :extra-options="equityUsersChart.options">
            </line-chart>
          </card>
        </div>

      </div>

      <div class="row">

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('effectiveDrawdown') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('effectiveDrawdown_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="equityUsersChart.labels"
                        :data="equityUsersChart.effectiveDrawdown.data"
                        :extra-options="equityUsersChart.effectiveDrawdown.options">
            </line-chart>
          </card>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('effectiveLeverageUsed') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('effectiveLeverageUsed_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="equityUsersChart.labels"
                        :data="equityUsersChart.effectiveLeverageUsed.data"
                        :extra-options="equityUsersChart.effectiveLeverageUsed.options">
            </line-chart>
          </card>
        </div>

      </div>

      <div class="row">

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('percentualVolatilityEndurance') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('percentualVolatilityEndurance_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <line-chart :height="150"
                        :labels="equityUsersChart.labels"
                        :data="equityUsersChart.percentualVolatilityEndurance.data"
                        :extra-options="equityUsersChart.percentualVolatilityEndurance.options">
            </line-chart>
          </card>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
          <card>
            <template slot="header">
              <h4 class="card-title">
                {{ $t('monthlyReturnEquity') }}
                <div class="float-right status-icon">
                  <el-tooltip :content="`${$t('monthlyReturnEquity_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i text-info"></i>
                  </el-tooltip>
                </div>
              </h4>
            </template>
            <bar-chart :labels="equityUsersChart.monthlyReturnEquity.labels"
                       :height="150"
                       :extra-options="equityUsersChart.monthlyReturnEquity.options"
                       :datasets="equityUsersChart.monthlyReturnEquity.datasets">
            </bar-chart>
          </card>
        </div>

      </div>

      <div class="row" v-if="inprogress == false">
        <div class="col-md-12 card">
          <div class="card-header">
            <h6>{{ $t('statisticstxt') }}</h6>
            <div class="small text-muted move-right">
              {{ eqChartInfo.lastupdate }}&nbsp;&nbsp;
              <el-tooltip :content="eqReportingStatus" :open-delay="100" placement="top">
                <i class="fa fa-circle" :class="`text-${eqStatusType}`"></i>
              </el-tooltip>
            </div>
          </div>

          <div class="card-body row">

            <div class="table-responsive col-md-12">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('totalReturnstxt') }}
                      <el-tooltip :content="`${$t('totalReturnsEquitytxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="equityUsersChart.stats.accumulatedEquityGrowth"
                      :class="(equityUsersChart.stats.accumulatedEquityGrowth > 0) ? 'text-success' : 'text-danger'">
                      {{ (equityUsersChart.stats.accumulatedEquityGrowth * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('maxEffectiveLeverageUsedtxt') }}
                      <el-tooltip :content="`${$t('maxEffectiveLeverageUsedtxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="equityUsersChart.stats.maxEffectiveLeverageUsed"
                      :class="(equityUsersChart.stats.maxEffectiveLeverageUsed <= 10) ? 'text-success' : 'text-danger'">
                      {{ (equityUsersChart.stats.maxEffectiveLeverageUsed).toFixed(2) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('monthsSinceInception') }}
                      <el-tooltip :content="`${$t('monthsSinceInception_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="equityUsersChart.stats.monthsSinceInception">
                      {{ (equityUsersChart.stats.monthsSinceInception) }}
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('currentVolatilityEndurancetxt') }}
                      <el-tooltip :content="`${$t('currentVolatilityEndurancetxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="equityUsersChart.stats.percentualVolatilityEndurance">
                      {{ (equityUsersChart.stats.percentualVolatilityEndurance * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('dailyAverageReturnstxt') }}
                      <el-tooltip :content="`${$t('dailyAverageReturnsEquitytxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="equityUsersChart.stats.dailyAverageEquity"
                      :class="(equityUsersChart.stats.dailyAverageEquity > 0) ? 'text-success' : 'text-danger'">
                      {{ (equityUsersChart.stats.dailyAverageEquity * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('riskRatio') }}
                      <el-tooltip :content="`${$t('riskRatio_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="equityUsersChart.stats.riskRatio"
                      :class="(equityUsersChart.stats.riskRatio <= 1) ? 'text-success' : 'text-danger'">
                      {{ (equityUsersChart.stats.riskRatio).toFixed(2) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('monthlyAverageReturnstxt') }}
                      <el-tooltip :content="`${$t('monthlyAverageReturnsEquitytxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="equityUsersChart.stats.monthlyAverageEquity"
                      :class="(equityUsersChart.stats.monthlyAverageEquity > 0) ? 'text-success' : 'text-danger'">
                      {{ (equityUsersChart.stats.monthlyAverageEquity * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('averageNumberofMonthlyTradestxt') }}
                      <el-tooltip :content="`${$t('averageNumberofMonthlyTradestxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="equityUsersChart.stats.avgTradesPerMonth ">
                      {{ (equityUsersChart.stats.avgTradesPerMonth).toFixed(2) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>

                  <tr>
                    <td class="first-col label-stat">
                      {{ $t('maxEquityEffectiveDrawdown') }}
                      <el-tooltip :content="`${$t('maxEquityEffectiveDrawdown_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right pad-me-right label-stat" v-if="equityUsersChart.stats.maxEffectiveDrawdown"
                      :class="(equityUsersChart.stats.maxEffectiveDrawdown <= 0.3) ? 'text-success' : 'text-danger'">
                      {{ (equityUsersChart.stats.maxEffectiveDrawdown * 100).toFixed(2) }}%
                    </td>
                    <td class="text-right pad-me-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                    <td class="label-stat">
                      {{ $t('averageTradeDurationtxt') }}
                      <el-tooltip :content="`${$t('averageTradeDurationtxt_tt')}`" :open-delay="100" placement="top" popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i abit-down"></i>
                      </el-tooltip>
                    </td>
                    <td class="text-right label-stat" v-if="equityUsersChart.stats.avgOpenDuration">
                      {{ (equityUsersChart.stats.avgOpenDuration) }}
                    </td>
                    <td class="text-right label-stat text-danger" v-else>
                      {{ $t('notavailable') }}
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

        </div>

      </div>

    </tab-pane>
    <!--
    <tab-pane id="normalized_risk" key="normalized_risk">
      <span slot="title">
          {{ $t('normalized_risk') }}
      </span>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
          <h1>{{ $t('comingsoontxt') }}</h1>
        </div>
      </div>

    </tab-pane>
    -->

  </tabs>

</template>
<script>
  import {Tooltip} from 'element-ui'
  import {Tabs, TabPane, Card} from 'src/components/UIComponents'
  import LineChart from 'src/components/UIComponents/Charts/LineChart';
  import BarChart from 'src/components/UIComponents/Charts/BarChart';

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    components: {
      [Tooltip.name]:
      Tooltip,
      LineChart,
      BarChart,
      TabPane,
      Tabs,
      Card
    },
    data() {
      return {
        adminusr: false,
        eqStatusType: 'success',
        balStatusType: 'success'
      }
    },
    name: 'stats-charts',
    props:{
      activeUsersChart: {
        type: Object,
        description: 'Chart Data'
      },
      equityUsersChart: {
        type: Object,
        description: 'Chart Data'
      },
      inprogress: {
        type: Boolean,
        description: 'In Progress'
      },
      showdata: {
        type: Boolean,
        description: 'Show Data'
      },
      eqChartInfo: {
        type: Object,
        description: 'Chart Info Data'
      },
      eqReportingStatus: {
        type: String,
        description: 'Status of the chart'
      },
      balChartInfo: {
        type: Object,
        description: 'Chart Info Data'
      },
      balReportingStatus: {
        type: String,
        description: 'Status of the chart'
      },
      typePills: {
        type: String,
        description: 'Style of Pills',
        default: "primary"
      }
    },
    methods: {
      assignChartData() {
        return {
          activeUsersChart: this.activeUsersChart ? this.activeUsersChart : {},
          equityUsersChart: this.equityUsersChart ? this.equityUsersChart : {}
        }
      }
    },
    mounted() {
      if (user_data !== null && user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }
      this.assignChartData({});
    },
    watch: {
      'eqChartInfo': function (newVal) {
        if (this.eqChartInfo.orders_fail || this.eqChartInfo.symbol_price_fail || this.eqChartInfo.trade_history_fail) {
          this.eqStatusType = 'warning';
          if (this.eqChartInfo.orders_fail && this.eqChartInfo.symbol_price_fail && this.eqChartInfo.trade_history_fail) {
            this.eqStatusType = 'danger';
          }
        }
      },
      'balChartInfo': function (newVal) {
        if (this.balChartInfo.orders_fail || this.balChartInfo.symbol_price_fail || this.balChartInfo.trade_history_fail) {
          this.balStatusType = 'warning';
          if (this.balChartInfo.orders_fail && this.balChartInfo.symbol_price_fail && this.balChartInfo.trade_history_fail) {
            this.balStatusType = 'danger';
          }
        }
      }
    }
  }

</script>
<style scoped>
  .table {
    margin-bottom: 0rem;
  }
  .table-responsive {
    overflow: hidden;
  }
</style>
