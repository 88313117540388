export default {
  namespaced: true,
  state:{
    currentRow: {},
  },
  getters: {},
  mutations: {
    ['SET_CURRENT_ROW'](state, value) {
      state.currentRow = value
    }
  },
  actions: {
    setCurrentRow({commit}, currentRow) {
      commit('SET_CURRENT_ROW', currentRow)
    },
  }
}
