<template>
  <module
    ref="module"
    if="blacklist"
    titleIcon="fa fa-user-times "
    :title="$t('blacklist')"
    :use-default-list="false"
  >
    <template slot="toolbar-global">
      <p-button type="info" @click="showCreateModal=true">
        <i class="fa fa-plus-square"></i> {{ txt.new }}
      </p-button>
    </template>
    <div slot="global">
      <!-- In progress -->
      <div v-if="inProgress" class="text-center">
        <h2>{{ $t('loadingtxt') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">
        <!-- Modal -->
        <modal :show.sync="showCreateModal" :required="true" headerClasses="justify-content-center">
          <h5 slot="header" class="modal-title">{{ txt.modalName }}</h5>
          <div class="row" style="height: 150px; align-content: center">
            <fg-input class="col-md-12" v-model="newItem" :label="$t('nametxt')"></fg-input>
            <fg-input class="col-md-12" v-model="itemDescription" :label="$t('description')"></fg-input>
          </div>
          <template slot="footer">
            <div class="left-side">
              <p-button link v-on:click="createItem"> {{ txt.create }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" link v-on:click="showCreateModal=false"> {{ txt.cancelbtn }}</p-button>
            </div>
          </template>
        </modal>

        <div class="col-md-12">
          <div >
            <div class="list">
              <el-container class="module-body">
                <el-main>
                  <data-tables-server ref="datatable"
                                      :data="data"
                                      :total="total"
                                      :table-props="configTable"
                                      @query-change="loadData"
                                      @current-change="setCurrentRow">
                    <div slot="empty">{{ $t('norecordsfounded') }}</div>
                    <el-table-column :label="$t('nametxt')" align="left">
                      <template slot-scope="props">
                        {{ props.row.name }}
                      </template>
                    </el-table-column>
                    0
                    <el-table-column :label="$t('description')" align="left">
                      <template slot-scope="props">
                        {{ props.row.description }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('action')" align="center" width="350">
                      <template slot-scope="props">
                        <p-button type="danger"
                                  size="sm"
                                  v-on:click="confirmRemoveModal(props.row)">
                          <i slot="label" class="fa fa-times"></i>
                        </p-button>
                      </template>
                    </el-table-column>
                  </data-tables-server>
                </el-main>
              </el-container>
            </div>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
  </module>
</template>

<script>
import Vue from "vue";
import { DataTablesServer } from 'vue-data-tables';
import {Loading, Pagination, Container, Aside} from 'element-ui';
import swal from "sweetalert2";
import Modal from "@/components/UIComponents/Modal";
import {mapActions, mapState} from "vuex";
import {ListPayload} from "@/models/commons";

Vue.use(Aside);
Vue.use(Container);
Vue.use(DataTablesServer);
Vue.use(Loading);
Vue.use(Pagination);

export default {
  name: "BlackList",
  components:{Modal},
  computed: {
    ...mapState('blacklist', ['currentRow']),
    configTable() {
      return {
        height: document.querySelector('.content').clientHeight - 160,
        highlightCurrentRow: true,
        stripe: true,
      };
    },
  },
  data() {
    return {
      inProgress: true,
      showCreateModal: false,
      newItem: '',
      itemDescription: '',
      data: [],
      total: 0,
      filters: {
        name: '',
      },
      txt: {
        modalName: this.$t('insertNew'),
        create: this.$t('create'),
        cancelbtn: this.$t('cancel'),
        blacklist: this.$t('blacklist'),
        new: 'New',

      }
    }
  },
  methods:{
    ...mapActions('blacklist', ['setCurrentRow']),
    loadData(payload) {
      if (payload.page === null) {
        return;
      }
      this.$listBlacklist({url: '/strategy/blacklist', payload: this.$serializeToQueryString(new ListPayload(payload))})
        .then(this.loadBlackList, this.failop)
    },
    loadBlackList(resp){
      if(resp.success) {
        this.data = resp.data.results
        this.total = resp.data.length;
        this.inProgress = false
      }
    },
    confirmRemoveModal(row) {
      swal({
        title: this.$t('attentiontxt'),
        text: this.$t('removeBlacklistItem', [row.name]),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
      }).then(() => {
          this.removeBlacklistItem(row.id)
            .then(this.removeSuccess(), this.failOp)
            .catch(this.failOp);
        }).catch(swal.noop);
    },
    removeSuccess(resp) {
      swal('Good job!', 'Item removed', 'success')
        .then(() => {
          this.operationConcluded()
      })
    },
    operationConcluded(payload) {
      this.newItem = ''
      this.itemDescription = ''
      this.inProgress = false
      this.loadData(new ListPayload(payload))
    },
    createItem(){
      let param = {
        newItem: this.newItem,
        description: this.itemDescription
      }
      this.insertNewBlacklistItem(param).then(this.createItemSuccess, this.failOp)
    },
    createItemSuccess(resp){
      if(!resp.success) {
        return this.failOp()
      }
      this.showCreateModal = false
      swal('Good job!', 'New item created', 'success').then( () => {
        this.operationConcluded();
      })
    },
    failOp(resp){
      console.log("ERRO", resp)
      swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "error")
    }
  },
  mounted(){
    this.inProgress = false
  },
  watch: {
    currentRow(value) {
      if(value && !value.template) {
        this.$refs.datatable.queryChange('refresh');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
h5 {
  display: inline-block;
  padding: 1rem;
  margin: 0;
  i {
    padding-right: 1rem;
  }
}
hr {
  margin: 0 !important;
}
.card-body {
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.el-main {
  padding-top: 0;
  padding-bottom: 0;
}
.el-aside {
  border-left: $border $light-gray;
  fieldset {
    padding: $padding-input-vertical 0 0 $padding-input-vertical;
    > label {
      font-weight: bold;
      font-size: $font-size-medium;
      color: $font-color;
    }
  }
}
</style>
