import {i18n} from "src/assets/i18n-plugin";

export const SignalOptions = {
  REPLICATEALL: {
    value: {
      "receiveNewTradeSignals": true,
      "receiveCloseTradeSignals": true,
      "assetsPairsToReceiveSignals": "all",
      "tradeSide": "all"	,
    },
    label() {
      return i18n.t('param_signals_replicateall')
    },
    info() {
      return i18n.t('param_signals_advanced_openclose_info')
    },
  },
  ADVANCED: {
    OPENCLOSE: {
      BOTH: {
        value: {
          "receiveNewTradeSignals": true,
          "receiveCloseTradeSignals": true,
        },
        label() {
          return i18n.t('param_signals_advanced_openclose_both')
        },
        info() {
          return i18n.t('param_signals_advanced_openclose_both_info')
        },
      },
      OPEN: {
        value: {
          "receiveNewTradeSignals": true,
          "receiveCloseTradeSignals": false,
        },
        label() {
          return i18n.t('param_signals_advanced_openclose_open')
        },
        info() {
          return i18n.t('param_signals_advanced_openclose_open_info')
        },
      },
      CLOSE: {
        value: {
          "receiveNewTradeSignals": false,
          "receiveCloseTradeSignals": true,
        },
        label() {
          return i18n.t('param_signals_advanced_openclose_close')
        },
        info() {
          return i18n.t('param_signals_advanced_openclose_close_info')
        },
      },
      BLOCKED: {
        value: {
          "receiveNewTradeSignals": false,
          "receiveCloseTradeSignals": false,
        },
        label() {
          return i18n.t('param_signals_advanced_openclose_blocked')
        },
        info() {
          return i18n.t('param_signals_advanced_openclose_blocked_info')
        },
      },
    },
    ASSETS: {
      ALL: {
        value: {
          "assetsPairsToReceiveSignals": "all",
          "allButTheseAssetsPairsReceiveSignal": null,
          "onlyTheseAssetsPairsReceiveSignal": null,
        },
        label() {
          return i18n.t('param_signals_advanced_assets_all')
        },
        info() {
          return i18n.t('param_signals_advanced_assets_all_info')
        },
      },
      EXCEPT: {
        value: {
          "assetsPairsToReceiveSignals": "allButTheseAssetsPairsReceiveSignal",
          "onlyTheseAssetsPairsReceiveSignal": null,
          "allButTheseAssetsPairsReceiveSignal": null,
        },
        getFilledObject(value) {
          return Object.assign({}, this.value, {allButTheseAssetsPairsReceiveSignal: value})
        },
        label() {
          return i18n.t('param_signals_advanced_assets_except')
        },
        info() {
          return i18n.t('param_signals_advanced_assets_except_info')
        },
      },
      ONLY: {
        value: {
          "assetsPairsToReceiveSignals": "onlyTheseAssetsPairsReceiveSignal",
          "onlyTheseAssetsPairsReceiveSignal": null,
          "allButTheseAssetsPairsReceiveSignal": null,
        },
        getFilledObject(value) {
          return Object.assign({}, this.value, {onlyTheseAssetsPairsReceiveSignal: value})
        },
        label() {
          return i18n.t('param_signals_advanced_assets_only')
        },
        info() {
          return i18n.t('param_signals_advanced_assets_only_info')
        },
      },
    },
    REPLICATION: {
      BOTH: {
        value: {
          "tradeSide": "all",
        },
        label() {
          return i18n.t('param_signals_advanced_replication_both')
        },
        info() {
          return i18n.t('param_signals_advanced_replication_both_info')
        },
      },
      BUY: {
        value: {
          "tradeSide": "onlyBuy",
        },
        label() {
          return i18n.t('param_signals_advanced_replication_buy')
        },
        info() {
          return i18n.t('param_signals_advanced_replication_buy_info')
        },
      },
      SELL: {
        value: {
          "tradeSide": "onlySell",
        },
        label() {
          return i18n.t('param_signals_advanced_replication_sell')
        },
        info() {
          return i18n.t('param_signals_advanced_replication_sell_info')
        },
      },
    },
  },
}
